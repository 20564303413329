<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/KabSemarang.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">PETA SiPEJALAN</span>
                <span v-else>SiPEJALAN MAPS</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Home</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Peta SiPejalan</span>
                    <span v-else>SiPejalan Maps</span>
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <div class="container-fluid">&nbsp;</div>
    <div class="container-fluid">
        <div class="row px-2 align-items-center">
            <div class="col-sm-4">
                <div class="info-box bg-info">
                    <span class="info-box-icon bg-success elevation-1"><i class="fas fa-chalkboard-teacher"></i></span>

                    <div class="info-box-content">
                        <span class="info-box-text" v-if="(bahasa === null) || (bahasa == 'ina')">JUMLAH KETERSEDIAAN
                            FASILITAS JALAN</span>
                        <span class="info-box-text" v-else>NUMBER OF ROAD FACILITIES AVAILABLE</span>
                        <span class="info-box-number">
                            {{ G_numFormat(ketersediaan) }}
                            <small>Data</small>
                        </span>
                    </div>
                    <!-- /.info-box-content -->
                </div>
            </div>
            <div class="col-sm-4">
                <div class="info-box bg-info">
                    <span class="info-box-icon bg-success elevation-1"><i class="fas fa-map-marked-alt"></i></span>

                    <div class="info-box-content">
                        <span class="info-box-text" v-if="(bahasa === null) || (bahasa == 'ina')">JUMLAH KEBUTUHAN
                            FASILITAS JALAN</span>
                        <span class="info-box-text" v-else>NUMBER OF ROAD FACILITIES REQUIRED</span>
                        <span class="info-box-number">
                            {{ G_numFormat(kebutuhan) }}
                            <small>Data</small>
                        </span>
                    </div>
                    <!-- /.info-box-content -->
                </div>
            </div>
            <div class="col-sm-4">
                <div class="info-box bg-info">
                    <span class="info-box-icon bg-success elevation-1"><i class="fas fa-building"></i></span>

                    <div class="info-box-content">
                        <span class="info-box-text" v-if="(bahasa === null) || (bahasa == 'ina')">JUMLAH LAMPU
                            PENERANGAN JALAN UMUM</span>
                        <span class="info-box-text" v-else>NUMBER OF PUBLIC STREET LIGHTING LIGHTS</span>
                        <span class="info-box-number">
                            {{ G_numFormat(ketersediaan_lpju) }}
                            <small>Data</small>
                        </span>
                    </div>
                    <!-- /.info-box-content -->
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row g-0 align-items-center">
            <div class="col-sm-12">
                <button type="button" class="btn btn-tool" id="kosong" @click="datakosong()" style="display: none;"><i
                        class="fas fa-minus text-white"></i></button>
                <iframe :src="sourcenya" class="col-sm-12 text-center" style="min-height: 95vh;" frameborder="0"
                    id="maps" scrolling="no" @load="loadpeta()"></iframe>
            </div>
        </div>
    </div>
    <!-- Header End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';

import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            bahasa: localStorage.getItem("bahasasistem"),
            tahun: new Date().getFullYear(),
            linknya: process.env.VUE_APP_URL_API,
            sourcenya: '',
            ketersediaan: 0,
            kebutuhan: 0,
            ketersediaan_lpju: 0,
        };
    },
    methods: {
        loadpeta() {
            this.halamanloading = true;
            const message = this.linknya;
            const iframe = document.querySelector("iframe");
            this.sourcenya = '';
            this.sourcenya = './map.html';
            iframe.contentWindow.postMessage(message, "*");
            this.halamanloading = false;
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("datakita-GetdataResumeKetersediaan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = Response.data.content.data;
                        this.ketersediaan = Response.data.content.data[0].jumlah;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.ketersediaan = 0;
                console.log(error);
                this.halamanloading = false;
            });

            this.halamanloading = true;
            var random = Math.random();
            await mainAPI.get("datakita-GetdataResumeKebutuhan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = Response.data.content.data;
                        this.kebutuhan = Response.data.content.data[0].jumlah;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.kebutuhan = 0;
                console.log(error);
                this.halamanloading = false;
            });

            this.halamanloading = true;
            var random = Math.random();
            await mainAPI.get("datakita-GetdataResumeLPJU?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = Response.data.content.data;
                        this.ketersediaan_lpju = Response.data.content.data[0].jumlah;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.ketersediaan_lpju = 0;
                console.log(error);
                this.halamanloading = false;
            });
        },
        datakosong() {
            swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000
            }).fire({
                icon: 'warning',
                title: 'Data Layer Gagal Diunduh..',
            });
            $('.swal2-container').css("z-index", '999999');
        },
    },
    mounted() {
        this.halamanloading = true;
        this.ambildata();
    },
}
</script>

<style></style>