import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Beranda',
    component: () => import('../../components/views/Publik/Beranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../../components/views/Publik/Login.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Aduan',
    name: 'Aduan',
    component: () => import('../../components/views/Publik/Aduan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Publikasi',
    name: 'Publikasi',
    component: () => import('../../components/views/Publik/Publikasi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Ketersediaan',
    name: 'Ketersediaan',
    component: () => import('../../components/views/Publik/Ketersediaan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/LPJU',
    name: 'LPJU',
    component: () => import('../../components/views/Publik/LPJU.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Kebutuhan',
    name: 'Kebutuhan',
    component: () => import('../../components/views/Publik/Kebutuhan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/KontakKami',
    name: 'KontakKami',
    component: () => import('../../components/views/Publik/KontakKami.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/GIS',
    name: 'GIS',
    component: () => import('../../components/views/Publik/GIS.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: () => import('../../components/views/Dashboard/Dashboard.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Slider',
    name: 'Slider',
    component: () => import('../../components/views/Backoffice/Slider/Slider.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesSlider/:id',
    name: 'ProsesSlider',
    component: () => import('../../components/views/Backoffice/Slider/ProsesSlider.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Dokumen',
    name: 'Dokumen',
    component: () => import('../../components/views/Backoffice/Dokumen/Dokumen.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesDokumen/:id',
    name: 'ProsesDokumen',
    component: () => import('../../components/views/Backoffice/Dokumen/ProsesDokumen.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/InfoBeranda',
    name: 'InfoBeranda',
    component: () => import('../../components/views/Backoffice/InfoBeranda/InfoBeranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesInfoBeranda/:id',
    name: 'ProsesInfoBeranda',
    component: () => import('../../components/views/Backoffice/InfoBeranda/ProsesInfoBeranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/KategoriData',
    name: 'KategoriData',
    component: () => import('../../components/views/Backoffice/KategoriData/KategoriData.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesKategoriData/:id',
    name: 'ProsesKategoriData',
    component: () => import('../../components/views/Backoffice/KategoriData/ProsesKategoriData.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/AduanAdmin',
    name: 'AduanAdmin',
    component: () => import('../../components/views/Backoffice/Aduan/Aduan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/LayerPeta',
    name: 'LayerPeta',
    component: () => import('../../components/views/Backoffice/LayerPeta/LayerPeta.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesLayerPeta/:id',
    name: 'ProsesLayerPeta',
    component: () => import('../../components/views/Backoffice/LayerPeta/ProsesLayerPeta.vue'),
    meta: {
      transition: "zoom",
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  routes: routes,
  linkActiveClass: 'active',
})

export default router;
