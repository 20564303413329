<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/KabSemarang.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">ADUAN MASYARAKAT</span>
                <span v-else>PUBLIC COMPLAINTS</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Home</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Aduan Masyarakat</span>
                    <span v-else>Public Complaints</span>
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid blog py-2">&nbsp;</div>
    <div class="container-fluid bg-breadcrumbberanda wow fadeInDown">
        <div class="section-title mb-2 wow fadeInDown" data-wow-delay="0.1s">
            <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">
                <div class="section-title text-start">
                    <h4 class="sub-title pe-3 mb-0 pmistitelberita">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">DISCLAIMER</span>
                        <span v-else>DISCLAIMER</span>
                    </h4>
                    <br><br>
                    <p class="mb-4">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Silahkan memasukkan aduan yang hendak
                            disampaikan, silahkan mengisikan data identitas diri secara lengkap dan tepat.
                            Kekeliruan mengisikan data identitas mengakibatkan admin tidak bisa memberikan tindak
                            lanjut dari aduan yang disampaikan.<span class="text-danger"> Silahkan mengaktifkan akses
                                lokasi dari perangkat anda
                                untuk ketepatan lokasi dari aduan yang disampaikan.</span></span>
                        <span v-else>Please enter the complaint you want to submit, please fill in your personal
                            identity data completely and accurately. Errors in filling in identity data result in
                            the admin being unable to provide follow-up to the complaint submitted.<span
                                class="text-danger"> Please activate
                                location access on your device for accurate location of the complaint
                                submitted.</span></span>
                    </p>
                </div>
            </div>
        </div>
        <div class="row g-4 align-items-center">
            <div class="col-lg-2 col-xl-3 wow fadeInRight" data-wow-delay="0.3s"></div>
            <div class="col-lg-8 col-xl-6 contact-form wow fadeInLeft" data-wow-delay="0.1s">
                <div class="row g-3 px-4">
                    <div class="col-lg-12 col-xl-6">
                        <div class="form-floating">
                            <input type="text" class="form-control bg-transparent border border-white" id="nama"
                                placeholder="Your Name" v-model="nama">
                            <label for="nama" v-if="(bahasa === null) || (bahasa == 'ina')">Masukkan Nama</label>
                            <label for="nama" v-else>Your Name</label>
                        </div>
                    </div>
                    <div class="col-lg-12 col-xl-6">
                        <div class="form-floating">
                            <input type="text" class="form-control bg-transparent border border-white" id="telp"
                                placeholder="Phone" v-model="telp">
                            <label for="telp" v-if="(bahasa === null) || (bahasa == 'ina')">Masukkan Telepon</label>
                            <label for="telp" v-else>Your Phone</label>
                        </div>
                    </div>
                    <div class="col-lg-12 col-xl-12">
                        <div class="form-floating">
                            <input type="text" class="form-control bg-transparent border border-white" id="alamat"
                                placeholder="Address" v-model="alamat">
                            <label for="alamat" v-if="(bahasa === null) || (bahasa == 'ina')">Masukkan Alamat</label>
                            <label for="alamat" v-else>Your Address</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-floating">
                            <input type="text" class="form-control bg-transparent border border-white" id="judul"
                                placeholder="Subject" v-model="judul">
                            <label for="judul" v-if="(bahasa === null) || (bahasa == 'ina')">Masukkan Judul</label>
                            <label for="judul" v-else>Subject</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-floating">
                            <textarea class="form-control bg-transparent border border-white"
                                placeholder="Leave a message here" id="konten" style="height: 160px"
                                v-model="konten"></textarea>
                            <label for="konten" v-if="(bahasa === null) || (bahasa == 'ina')">Masukkan Aduan</label>
                            <label for="konten" v-else>Message</label>
                        </div>
                    </div>
                    <div class="col-3"></div>
                    <div class="col-6">
                        <div class="input-group justify-content-center">
                            <div class="custom-control custom-switch custom-switch-on-primary">
                                <input type="checkbox" class="custom-control-input" id="customSwitchAuth">
                                <label class="custom-control-label" for="customSwitchAuth" @click="rubahisi()"
                                    style="font-size: small;">
                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Autentikasi Pengguna
                                        Manusia</span>
                                    <span v-else>Human User Authentication</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-3"></div>
                    <div class="col-lg-12 col-xl-3"></div>
                    <div class="col-lg-12 col-xl-6">
                        <button class="btn btn-dark text-white w-100 py-3" :disabled="showHuman == false"
                            @click="isiaduan()">Send
                            Message</button>
                    </div>
                    <div class="col-lg-12 col-xl-3"></div>
                    <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s"></div>
                </div>
            </div>
            <div class="col-lg-2 col-xl-3 wow fadeInRight" data-wow-delay="0.3s"></div>
        </div>
    </div>
    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';

import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            sourcenya: '',
            showHuman: false,
            nama: '',
            telp: '',
            alamat: '',
            judul: '',
            konten: '',
            LATITUDE: '',
            LONGITUDE: '',
            validasi: false,
        };
    },
    methods: {
        rubahisi() {
            if (this.showHuman == false) {
                this.showHuman = true;
            }
            else {
                this.showHuman = false;
            }
        },
        validasidata() {
            if (document.getElementById('nama').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('nama').focus();
                return false;
            }
            if (document.getElementById('telp').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('telp').focus();
                return false;
            }
            if (document.getElementById('alamat').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('alamat').focus();
                return false;
            }
            if (document.getElementById('judul').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('judul').focus();
                return false;
            }
            if (this.konten == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('konten').focus();
                return false;
            }
            this.validasi = true;
            return;
        },
        isiaduan() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            if (navigator.geolocation) {
                const successCallback = (position) => {
                    this.LATITUDE = position.coords.latitude;
                    this.LONGITUDE = position.coords.longitude;
                    if ((this.LATITUDE == '') || (this.LONGITUDE == '')) {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: 'Koordinat Tidak Valid',
                        });
                        this.validasi = false;
                        this.showHuman = false;
                        return false;
                    }

                    var fd = new FormData();
                    fd.append("nama", this.nama);
                    fd.append("telp", this.telp);
                    fd.append("alamat", alamat);
                    fd.append("judul", this.judul);
                    fd.append("konten", this.konten);
                    fd.append("LONGITUDE", this.LONGITUDE);
                    fd.append("LATITUDE", this.LATITUDE);
                    fd.append("createdby", 'publik');
                    const data = {
                        nama: this.nama,
                        telp: this.telp,
                        alamat: this.alamat,
                        judul: this.judul,
                        konten: this.konten,
                        LONGITUDE: this.LONGITUDE,
                        LATITUDE: this.LATITUDE,
                        createdby: 'publik',
                    };
                    const mainAPI = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    });

                    mainAPI.post("datakita-storeAduan", data).then(
                        Response => {
                            if (Response.data.response == 'error') {
                                swal.fire('Peringatan', Response.data.message, 'error');
                            }
                            else {
                                swal.fire('Informasi', 'Data aduan telah tersimpan..', 'success').then(function () {
                                    window.location.replace("/Aduan");
                                });
                            }
                            $('.swal2-container').css("z-index", '999999');
                        }
                    ).catch(function (error) {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: error
                        });
                        $('.swal2-container').css("z-index", '999999');
                    }
                    )
                };
                const errorCallback = (error) => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: error,
                    });
                    return false;
                };
                navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
            }
        },
    },
    mounted() {
        this.halamanloading = false;
    },
}
</script>

<style></style>