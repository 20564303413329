<template>
    <div class="container-fluid bg-dark px-5 d-none d-lg-block">
        <div class="row gx-0 align-items-center" style="height: 45px;">
            <div class="col-lg-8 text-center text-lg-start mb-lg-0">
                <div class="d-flex flex-wrap">
                    <a href="#" class="text-light me-4">Dinas Perhubungan Kab. Semarang</a>
                    <a href="#" class="text-light me-4"><i class="fas fa-phone-alt text-white me-2"></i>(0298)
                        522530</a>
                    <a href="#" class="text-light me-0"><i
                            class="fas fa-envelope text-white me-2"></i>rekayasalalin@gmail.com</a>
                </div>
            </div>
            <div class="col-lg-4 text-center text-lg-end">
                <div class="d-flex align-items-center justify-content-end">
                    <a href="#" class="btn nav-fill me-6 text-white">Bahasa / Language</a>
                    <a href="#" class="btn btn-light btn-square border rounded-circle nav-fill me-3"
                        :class="[((bahasa === null) || (bahasa == 'ina') ? 'btn-pmis' : '')]"
                        @click="gantibahasa('ina')"><img src="../../../../../src/assets/img/ina.jpg" height="20"></a>
                    <a href="#" class="btn btn-light btn-square border rounded-circle nav-fill me-3"
                        :class="[((bahasa == 'eng') ? 'btn-pmis' : '')]" @click="gantibahasa('eng')"><img
                            src="../../../../../src/assets/img/eng.jpg" height="20"></a>
                </div>
            </div>
        </div>
    </div>
    <!-- Topbar End -->
    <div class="container-fluid position-relative p-0">
        <nav class="navbar navbar-expand-lg navbar-light bg-white px-4 px-lg-5 py-3 py-lg-0">
            <a href="/" class="navbar-brand p-0">
                <h5 class="text-danger m-0 pmisdanger"><img src="../../../../../src/assets/img/sanding.png"
                        height="80"> SiPEJALAN</h5>
                <!-- <img src="img/logo.png" alt="Logo"> -->
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span class="fa fa-bars"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <div class="navbar-nav ms-auto py-0">
                    <a href="/"
                        :class="[((route.name == 'Beranda') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">BERANDA</span>
                        <span v-else>HOME</span>
                    </a>
                    <a href="/GIS"
                        :class="[((route.name == 'GIS') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">PETA SiPEJALAN</span>
                        <span v-else>SiPEJALAN MAPS</span>
                    </a>
                    <div class="nav-item dropdown">
                        <a href="#" data-bs-toggle="dropdown"
                            :class="[((route.name == 'Ketersediaan') ? 'nav-link active dropdown-toggle' : 'nav-link dropdown-toggle')]">
                            <span v-if="(bahasa === null) || (bahasa == 'ina')">DATA FASILITAS JALAN</span>
                            <span v-else>ROAD FACILITIES DATA</span>
                        </a>
                        <div class="dropdown-menu m-0">
                            <a href="/Ketersediaan"
                                :class="[((route.name == 'Ketersediaan') ? 'dropdown-item active' : 'dropdown-item')]">
                                <span v-if="(bahasa === null) || (bahasa == 'ina')">KETERSEDIAAN FASILITAS JALAN</span>
                                <span v-else>AVAILABILITY OF ROAD FACILITIES</span>
                            </a>
                            <a href="/Kebutuhan"
                                :class="[((route.name == 'Kebutuhan') ? 'dropdown-item active' : 'dropdown-item')]">
                                <span v-if="(bahasa === null) || (bahasa == 'ina')">KEBUTUHAN FASILITAS JALAN</span>
                                <span v-else>NEED FOR ROAD FACILITIES</span>
                            </a>
                            <a href="/LPJU"
                                :class="[((route.name == 'LPJU') ? 'dropdown-item active' : 'dropdown-item')]">
                                <span v-if="(bahasa === null) || (bahasa == 'ina')">KETERSEDIAAN LPJU</span>
                                <span v-else>LPJU AVAILABILITY</span>
                            </a>
                        </div>
                    </div>
                    <a href="/Aduan"
                        :class="[((route.name == 'Aduan') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">ADUAN MASYARAKAT</span>
                        <span v-else>PUBLIC COMPLAINTS</span>
                    </a>
                    <a href="/Publikasi"
                        :class="[((route.name == 'Publikasi') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">PUBLIKASI</span>
                        <span v-else>PUBLICATION</span>
                    </a>
                    <a href="/KontakKami"
                        :class="[((route.name == 'KontakKami') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">KONTAK KAMI</span>
                        <span v-else>CONTACT US</span>
                    </a>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';

export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        swal
    },
    data() {
        return {
            tabaktif: encodeURIComponent(CryptoJS.AES.encrypt('', 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString()),
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
        };
    },
    methods: {
        gantibahasa(bahasanya) {
            localStorage.setItem("bahasasistem", bahasanya);
            location.reload();
        },
    },
    mounted() {

    },
}
</script>

<style></style>