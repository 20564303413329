<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/KabSemarang.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">KONTAK KAMI</span>
                <span v-else>CONTACT US</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Home</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Kontak Kami</span>
                    <span v-else>Contact Us</span>
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid blog py-2">&nbsp;</div>
    <div class="container-fluid bg-breadcrumbberanda wow fadeInDown">
        <div class="section-title mb-2 wow fadeInDown" data-wow-delay="0.1s">
            <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">
                <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Jalan Soekarno Hatta No. 8, Bergas, Kebonan,
                        Samban, Kec. Bawen, Kabupaten Semarang, Jawa Tengah 50552 Telepon. (0298) 522530
                    </span>
                    <span v-else>Soekarno Hatta No. 8, Bergas, Kebonan,
                        Samban, District. Bawen, Semarang Regency, Central Java50552Telephone. (0298)
                        5225301</span>
                </div>
                <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">&nbsp;</div>
                <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">
                    <div class="d-flex justify-content-center mb-4">
                        <a class="btn btn-lg-square btn-light rounded-circle mx-2"
                            href="https://www.youtube.com/channel/UCQhptPJOB0pcOAX-7_ihO7w" target="_blank"><i
                                class="fab fa-youtube"></i></a>
                        <a class="btn btn-lg-square btn-light rounded-circle mx-2"
                            href="https://www.instagram.com/dishub_kabsemarang/?hl=id" target="_blank"><i
                                class="fab fa-instagram"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid blog py-0">
        <div class="container py-2">
            <div class="section-title mb-2 wow fadeInDown" data-wow-delay="0.1s">
                <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">
                    <div class="rounded h-100">
                        <iframe class="rounded w-100" style="height: 500px;" :src="sourcenya" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade" @load="loadkontak()"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';

import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            sourcenya: '',
        };
    },
    methods: {
        loadkontak() {
            this.halamanloading = true;
            const message = this.linknya;
            const iframe = document.querySelector("iframe");
            this.sourcenya = '';
            this.sourcenya = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15833.301804889967!2d110.4232943!3d-7.203661!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7086a12ddf8117%3A0x596fdc9ccd76e5f3!2sDinas%20Perhubungan%20Kabupaten%20Semarang!5e0!3m2!1sid!2sid!4v1703122283126!5m2!1sid!2sid';
            iframe.contentWindow.postMessage(message, "*");
            this.halamanloading = false;
        },
    },
    mounted() {
        this.halamanloading = true;
    },
}
</script>

<style></style>