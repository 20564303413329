<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/KabSemarang.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">PUBLIKASI</span>
                <span v-else>PUBLICATION</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Home</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Publikasi</span>
                    <span v-else>Publication</span>
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid blog py-2">&nbsp;</div>
    <div class="container-fluid bg-breadcrumbberanda wow fadeInDown">
        <div class="container-fluid py-3 wow fadeInDown" data-wow-delay="0.1s">
            <div class="row g-4 align-items-center">
                <div class="col-lg-1 wow fadeInRight" data-wow-delay="0.1s"></div>
                <div class="col-lg-10 wow fadeInRight" data-wow-delay="0.1s">
                    <div class="col-sm-12 float-left pb-2">
                        <input type="text" v-model="carikata" class="form-control form-control-sm"
                            placeholder="Masukkan Kata Kunci Pencarian">
                    </div>
                    <div class="table-responsive table-wrapper px-2">
                        <table class="table m-0" id="tabeldata">
                            <thead>
                                <tr>
                                    <th style="width: 80px;">No</th>
                                    <th><span v-if="(bahasa === null) || (bahasa == 'ina')">Dokumen</span>
                                        <span v-else>Document</span>
                                    </th>
                                </tr>
                            </thead>
                            <template v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                                <tbody>
                                    <tr>
                                        <td>{{ urutlist + 1 }}</td>
                                        <td>
                                            <a :href="folder_pdf + datalist.filenya_dokumen" target="_blank">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">{{ datalist.nama
                                                    }}</span>
                                                <span v-else>{{ datalist.nama_eng }}</span></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                            <tbody v-if="(datapesan == 'data kosong')">
                                <tr>
                                    <td colspan="2">Data masih kosong</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-lg-1 wow fadeInRight" data-wow-delay="0.1s"></div>
            </div>
        </div>
    </div>
    <div class="container-fluid blog py-2">&nbsp;</div>
    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';

import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            datapesan: '',
            datamaster: [],
            folder_pdf: process.env.VUE_APP_URL_API + 'datakita_dokumen-DokumenPDF?f=',
            carikata: '',
        };
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("datakita-GetdataDokumenPublikasi?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = '';
                this.datamaster = [];
                console.log(error);
                this.halamanloading = false;
            });
        },
    },
    mounted() {
        this.ambildata();
    },
}
</script>

<style></style>