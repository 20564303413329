<template>
    <Top />
    <div class="modal fade" id="costumModalkelurahan" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
        aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
        style="margin: 0;">
        <div class="modal-dialog" style="position: absolute;top: 0;">
            <div class="modal-content" style="height: auto;min-width: 100vw;">
                <div class="modal-header bg-primary">
                    <h4 class="modal-title">

                        <span v-if="(bahasa === null) || (bahasa == 'ina')">
                            DAFTAR DATA KECAMATAN {{ labelkecamatan }}
                        </span>
                        <span v-else>DATA LIST OF {{ labelkecamatan }} DISTRICT </span>
                    </h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row px-4 text-center">
                        <div class="col-sm-4"></div>
                        <div class="col-sm-4 text-center">
                            <label>
                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Pilihan Desa</span>
                                <span v-else>Sub District Choice</span>
                            </label>&nbsp;
                            <v-select :options="datakelurahan" :reduce="(label) => label.code" label="label"
                                v-model="defaultSelectedkelurahan" @update:modelValue="onkelurahanChange"
                                style="background-color: #ffffff;"></v-select>
                        </div>
                        <div class="col-sm-4"></div>
                        <div class="col-sm-12 col-xl-12">&nbsp;</div>
                        <div class="col-sm-12 text-center align-items-center">
                            <BarChartTotalInvestasiKelurahan height="450" :options="chartOptionsTotalInvestasiKelurahan"
                                :series="seriesTotalInvestasiKelurahan"
                                @dataPointSelection="dataPointSelectionKelurahan">
                            </BarChartTotalInvestasiKelurahan>
                        </div>
                        <div class="col-sm-12 text-center"></div>
                        <div class="row g-4 px-4 align-items-center">
                            <div class="col-lg-12">
                                <div class="col-sm-12 float-left pb-2">
                                    <input type="text" v-model="carikatakelurahan" class="form-control form-control-sm"
                                        placeholder="Masukkan Kata Kunci Pencarian">
                                </div>
                                <div class="table-responsive table-wrapper px-2">
                                    <table class="table m-0" id="tabeldatakelurahan">
                                        <thead>
                                            <tr>
                                                <th style="width: 80px;">NO</th>
                                                <th><span v-if="(bahasa === null) || (bahasa == 'ina')">KELURAHAN</span>
                                                    <span v-else>VILLAGE</span>
                                                </th>
                                                <th style="width: 100px;"><span
                                                        v-if="(bahasa === null) || (bahasa == 'ina')">JUMLAH</span>
                                                    <span v-else>AMOUNT</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <template v-for="(datalist, urutlist) in filteredKataDataKelurahan"
                                            :key="urutlist">
                                            <tbody>
                                                <tr>
                                                    <td class="text-center">
                                                        <span style="font-size: small;">
                                                            {{ urutlist + 1 }}</span>
                                                    </td>
                                                    <td class="text-justify">
                                                        <a href="javascript:void(0)"
                                                            @click="dataSelectionKeLurahan(datalist.KELURAHAN)">
                                                            <span style="font-size: small;">
                                                                {{ datalist.KELURAHAN }}
                                                            </span>
                                                        </a>

                                                    </td>
                                                    <td class="text-center">
                                                        <span style="font-size: small;">
                                                            {{ G_numFormat(datalist.jumlah) }}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                        <tbody v-if="(datapesan == 'data kosong')">
                                            <tr>
                                                <td colspan="7">Data masih kosong</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer text-center">
                    <button type="button" class="btn btn-sm btn-danger3 text-white lebar2" data-dismiss="modal"> <i
                            class="far fa-times-circle"></i>
                        Tutup</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="costumModaldetail" data-easein="flipBounceYIn" tabindex="-1" role="dialog">
        <div class="modal-dialog" style="position: absolute;top: 0;">
            <div class="modal-content" style="height: auto;min-width: 100vw;">
                <div class="modal-header bg-red">
                    <h4 class="modal-title text-white">

                        <span v-if="(bahasa === null) || (bahasa == 'ina')">
                            DAFTAR DATA DESA {{ labelkelurahan }} - Jumlah Data: {{ G_numFormat(jumlahdata) }}
                        </span>
                        <span v-else>DATA LIST OF {{ labelkelurahan }} VILLAGE - Amount of data: {{
                            G_numFormat(jumlahdata)
                            }} </span>
                    </h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row px-4 text-center">
                        <div class="col-sm-12 text-center">
                            <div class="col-sm-12 text-left">
                                <label>
                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Halaman</span>
                                    <span v-else>Pages</span>
                                </label>&nbsp;
                                <select v-model="halaman" @change="onChangeHalaman($event)">
                                    <option v-for="index in jumlahhalaman" :key="index">
                                        {{ index }}</option>
                                </select>
                            </div>
                            <div class="col-sm-12 text-left">
                                <div class="table-responsive table-wrapper">
                                    <table class="table m-0" id="tabeldetailinvestasi">
                                        <thead>
                                            <tr>
                                                <th style="width: 50px;">NO</th>
                                                <th style="width: 50px;">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">NAMA</span>
                                                    <span v-else>NAME</span>
                                                </th>
                                                <th style="width: 100px;">LATITUDE</th>
                                                <th style="width: 100px;">LONGITUDE</th>
                                                <th style="width: 100px;">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">NAMA
                                                        JALAN</span>
                                                    <span v-else>STREET NAME</span>
                                                </th>
                                                <th style="width: 80px;">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">KONDISI</span>
                                                    <span v-else>CONDITION</span>
                                                </th>
                                                <th style="width: 150px;">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">ARAH</span>
                                                    <span v-else>DIRECTION</span>
                                                </th>
                                                <th style="width: 80px;">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">TAHUN</span>
                                                    <span v-else>YEAR</span>
                                                </th>
                                                <th style="width: 100px;">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">KELAS
                                                        JALAN</span>
                                                    <span v-else>STREET CLASS</span>
                                                </th>
                                                <th style="width: 100px;">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">JENIS</span>
                                                    <span v-else>TYPE</span>
                                                </th>
                                                <th style="width: 100px;">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">FOTO</span>
                                                    <span v-else>PHOTO</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <template v-for="(datalistdetail, urutlistdetail) in datadetail"
                                            :key="urutlist">
                                            <tbody>
                                                <tr>
                                                    <td class="komponenakhir">
                                                        {{ (halaman * 100) + urutlistdetail + 1 - 100 }}
                                                    </td>
                                                    <td class="komponenakhir text-justify">
                                                        {{ datalistdetail.NAMA }}
                                                    </td>
                                                    <td class="komponenakhir text-justify">
                                                        {{ datalistdetail.LATITUDE }}
                                                    </td>
                                                    <td class="komponenakhir text-justify">
                                                        {{ datalistdetail.LONGITUDE }}
                                                    </td>
                                                    <td class="komponenakhir text-justify">
                                                        {{ datalistdetail.NAMAJALAN }}
                                                    </td>
                                                    <td class="komponenakhir text-justify">
                                                        {{ datalistdetail.KONDISI }}
                                                    </td>
                                                    <td class="komponenakhir text-justify">
                                                        {{ datalistdetail.ARAH }}
                                                    </td>
                                                    <td class="komponenakhir text-justify">
                                                        {{ datalistdetail.TAHUN }}
                                                    </td>
                                                    <td class="komponenakhir text-justify">
                                                        {{ datalistdetail.KELASJALAN }}
                                                    </td>
                                                    <td class="komponenakhir text-justify">
                                                        {{ datalistdetail.JENIS }}
                                                    </td>
                                                    <td class="komponenakhir text-justify">
                                                        <img :src="folder_foto + datalistdetail.FOTO"
                                                            class="img-fluid rounded w-100 h-100" alt="Image">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </table>
                                </div>
                            </div>
                            <div class="col-sm-12 text-left">
                                <label>
                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Halaman</span>
                                    <span v-else>Pages</span>
                                </label>&nbsp;
                                <select v-model="halaman" @change="onChangeHalaman($event)">
                                    <option v-for="index in jumlahhalaman" :key="index">
                                        {{ index }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer text-center">
                    <button type="button" class="btn btn-sm btn-primary text-white lebar2" data-dismiss="modal"> <i
                            class="far fa-times-circle"></i>
                        Tutup</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/KabSemarang.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">KEBUTUHAN FASILITAS JALAN</span>
                <span v-else>NEED FOR ROAD FACILITIES</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Home</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Data Kebutuhan Fasilitas Jalan</span>
                    <span v-else>Road Facility Needs Data</span>
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid blog py-2">&nbsp;</div>
    <div class="container-fluid align-items-center bg-breadcrumbberanda wow fadeInDown">
        <div class="row px-4 text-center" data-wow-delay="0.3s">
            <div class="col-sm-4 text-center"></div>
            <div class="col-sm-4 text-center">
                <label>
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Pilihan Kecamatan</span>
                    <span v-else>District Choice</span>
                </label>&nbsp;
                <v-select :options="datakecamatan" :reduce="(label) => label.code" label="label"
                    v-model="defaultSelectedkecamatan" @update:modelValue="onkecamatanChange"
                    style="background-color: #ffffff;"></v-select>
            </div>
            <div class="col-sm-4 text-center"></div>
            <div class="col-sm-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">&nbsp;</div>
            <div class="col-sm-12 text-center align-items-center wow fadeInUp" data-wow-delay="0.1s">
                <BarChartTotalInvestasiKecamatan height="450" :options="chartOptionsTotalInvestasiKecamatan"
                    :series="seriesTotalInvestasiKecamatan" @dataPointSelection="dataPointSelectionKecamatan">
                </BarChartTotalInvestasiKecamatan>
            </div>
            <div class="col-sm-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">&nbsp;</div>
            <div class="container-fluid bg-breadcrumbberanda wow fadeInDown">
                <div class="container-fluid py-3 wow fadeInDown" data-wow-delay="0.1s">
                    <div class="row g-4 px-4 align-items-center">
                        <div class="col-lg-12 wow fadeInRight" data-wow-delay="0.1s">
                            <div class="col-sm-12 float-left pb-2">
                                <input type="text" v-model="carikata" class="form-control form-control-sm"
                                    placeholder="Masukkan Kata Kunci Pencarian">
                            </div>
                            <div class="table-responsive table-wrapper px-2">
                                <table class="table m-0" id="tabeldata">
                                    <thead>
                                        <tr>
                                            <th style="width: 80px;">NO</th>
                                            <th><span v-if="(bahasa === null) || (bahasa == 'ina')">KECAMATAN</span>
                                                <span v-else>DISTRICT</span>
                                            </th>
                                            <th style="width: 100px;"><span
                                                    v-if="(bahasa === null) || (bahasa == 'ina')">JUMLAH</span>
                                                <span v-else>AMOUNT</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <template v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                                        <tbody>
                                            <tr>
                                                <td class="text-center">
                                                    <span style="font-size: small;">
                                                        {{ urutlist + 1 }}</span>
                                                </td>
                                                <td class="text-justify">
                                                    <a href="javascript:void(0)"
                                                        @click="dataSelectionKecamatan(datalist.KECAMATAN)">
                                                        <span style="font-size: small;">
                                                            {{ datalist.KECAMATAN }}
                                                        </span>
                                                    </a>

                                                </td>
                                                <td class="text-center">
                                                    <span style="font-size: small;">
                                                        {{ G_numFormat(datalist.jumlah) }}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                    <tbody v-if="(datapesan == 'data kosong')">
                                        <tr>
                                            <td colspan="7">Data masih kosong</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import VueApexCharts from "vue3-apexcharts";
import vSelect from 'vue-select';
import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal, BarChartTotalInvestasiKecamatan: VueApexCharts, vSelect
        , BarChartTotalInvestasiKelurahan: VueApexCharts
    },
    data() {
        return {
            halamanloading: true,
            folder_foto: process.env.VUE_APP_URL_API + 'maps_datakita-GetGambarData?f=',
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            jumlahdata: 0,
            jumlahhalaman: 0,
            mulai: 0,
            halaman: 1,
            jumlah: 0,
            maximal: 0,
            minimal: 0,
            count: 0,
            datadetail: [],
            sourcenya: '',
            datakecamatan: [],
            kodekecamatan: '',
            datapesan: '',
            carikata: '',
            carikatakelurahan: '',
            datatemp: [],
            datamaster: [],
            datamasterkelurahan: [],
            labelkecamatan: '',
            labelkelurahan: '',
            defaultSelectedkecamatan: {
                code: 0,
                label: 'SEMUA KECAMATAN',
                tag: '',
            },
            selectedkecamatan: '',
            serieskecamatan: [],
            serieskelurahan: [],
            datakelurahan: [],
            defaultSelectedkelurahan: {
                code: 0,
                label: 'SEMUA DESA',
                tag: '',
            },
            selectedkelurahan: '',
            seriesTotalInvestasiKecamatan: [],
            chartOptionsTotalInvestasiKecamatan: {
                colors: [
                    '#6a6ba5',
                    '#f9a867',
                    '#2a6b9b',
                    '#6c98b7',
                    '#abc4d6',
                    '#d5e2eb'
                ],
                chart: {
                    height: '350',
                    width: '90%',
                    type: 'line',
                    stacked: 'true',
                    zoom: {
                        enabled: true,
                        type: 'x'
                    }
                },
                tooltip: {
                    followCursor: true,
                    shared: false
                },
                markers: {
                    size: 0,
                    fillOpacity: 0,
                    strokeOpacity: 0
                },
                stroke: {
                    width: 2
                },
                xaxis: {
                    labels: {
                        show: true,
                        rotate: -45,
                        rotateAlways: true,
                    },
                    categories: [],
                    title: {
                        text: 'Kecamatan (Subdistrict)',
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return val.toFixed(0).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    },
                    offsetY: -5,
                    style: {
                        fontSize: '8px',
                        colors: ['#5c6165', '#ff7f00'],
                    },
                },
                legend: {
                    showForSingleSeries: true
                },
                fill: {
                    opacity: 1
                },
                title: {
                    text: 'Jumlah Kebutuhan Fasilitas Jalan Per Kecamatan',
                    floating: true,
                    offsetY: -5,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                },
                subtitle: {
                    text: '(Number of Road Facilities Needs Per District)',
                    floating: true,
                    offsetY: 10,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                }
            },

            seriesTotalInvestasiKelurahan: [],
            chartOptionsTotalInvestasiKelurahan: {
                colors: [
                    '#6a6ba5',
                    '#f9a867',
                    '#2a6b9b',
                    '#6c98b7',
                    '#abc4d6',
                    '#d5e2eb'
                ],
                chart: {
                    height: '350',
                    width: '90%',
                    type: 'line',
                    stacked: 'true',
                    zoom: {
                        enabled: true,
                        type: 'x'
                    }
                },
                tooltip: {
                    followCursor: true,
                    shared: false
                },
                markers: {
                    size: 0,
                    fillOpacity: 0,
                    strokeOpacity: 0
                },
                stroke: {
                    width: 2
                },
                xaxis: {
                    labels: {
                        show: true,
                        rotate: -45,
                        rotateAlways: true,
                    },
                    categories: [],
                    title: {
                        text: 'Desa (Village)',
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return val.toFixed(0).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    },
                    offsetY: -5,
                    style: {
                        fontSize: '8px',
                        colors: ['#5c6165', '#ff7f00'],
                    },
                },
                legend: {
                    showForSingleSeries: true
                },
                fill: {
                    opacity: 1
                },
                title: {
                    text: 'Jumlah Kebutuhan Fasilitas Jalan Per Kelurahan',
                    floating: true,
                    offsetY: -5,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                },
                subtitle: {
                    text: '(Number of Road Facilities Needs Per Village)',
                    floating: true,
                    offsetY: 10,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                }
            },
        };
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
        filteredKataDataKelurahan() {
            const filteredWorkerskelurahan = this.carikatakelurahan === ""
                ? this.datamasterkelurahan
                : this.datamasterkelurahan.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikatakelurahan.toLowerCase()) !== -1);
            return filteredWorkerskelurahan;
        },
        JumlahfilteredKataDataKelurahan() {
            var jumlah = 0;
            try {
                if (this.filteredKataDataKelurahan === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataDataKelurahan.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        async dataSelectionKecamatan(KECAMATAN) {
            this.labelkecamatan = KECAMATAN;
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("datakita-GetKebutuhanFasilitasKelurahan?random=" + random + "&kecamatan=" + this.labelkecamatan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.serieskelurahan = [];
                        this.serieskelurahan = Response.data.content.label;
                        this.datamasterkelurahan = [];
                        this.datamasterkelurahan = Response.data.content.data;
                        this.chartOptionsTotalInvestasiKelurahan = {
                            xaxis: {
                                categories: Response.data.content.label
                            },
                            yaxis: {
                                tickAmount: 10,
                                labels: {
                                    formatter: function (value) {
                                        return value.toFixed(0).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    }

                                },
                            },
                        };
                        this.seriesTotalInvestasiKelurahan = [
                            {
                                "name": "Jumlah Kebutuhan Fasilitas Jalan (Number of Road Facilities Needs)",
                                "type": "bar",
                                "data": Response.data.content.value
                            },
                        ];

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.chartOptionsTotalInvestasiKelurahan = {
                    xaxis: {
                        categories: []
                    },
                };
                this.chartOptionsTotalInvestasiKelurahan = [];
                console.log(error);
                this.halamanloading = false;
            });
            $('#costumModalkelurahan').modal('show');
        },
        dataSelectionKeLurahan(KELURAHAN) {
            this.labelkelurahan = KELURAHAN;
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            mainAPI.get("datakita-GetKebutuhanFasilitasDetail?random=" + random + "&kecamatan=" + this.labelkecamatan + "&kelurahan=" + this.labelkelurahan).then(
                Response => {
                    this.datadetail = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadetail = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 100) + 1;
                        }
                        //$('#costumModalkelurahan').modal('hide');
                        $('#costumModaldetail').modal('show');
                        this.halamanloading = false;
                    }
                    else {
                        this.halamanloading = false;
                    }

                }
            ).catch(error => {
                console.log(error);
                this.datadetail = [];
                this.jumlahdata = 0;
                this.jumlahhalaman = 0;
                this.mulai = 0;
                this.halaman = 1;
                this.halamanloading = false;
            });
        },
        onChangeHalaman(event) {
            this.pindahhalaman(event.target.value);
        },
        async pindahhalaman(halamannya) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            mainAPI.get("datakita-GetKebutuhanFasilitasDetail?random=" + random + "&kecamatan=" + this.labelkecamatan + "&kelurahan=" + this.labelkelurahan + "&halaman=" + halamannya).then(
                Response => {
                    this.datadetail = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadetail = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 100) + 1;
                        }
                        //$('#costumModalkelurahan').modal('hide');
                        $('#costumModaldetail').modal('show');
                        this.halamanloading = false;
                    }
                    else {
                        this.halamanloading = false;
                    }

                }
            ).catch(error => {
                console.log(error);
                this.datadetail = [];
                this.jumlahdata = 0;
                this.jumlahhalaman = 0;
                this.mulai = 0;
                this.halaman = 1;
                this.halamanloading = false;
            });
        },
        async dataPointSelectionKelurahan(event) {
            var el = event.target;
            //var seriesIndex = parseInt(el.getAttribute("i"));
            var dataPointIndex = parseInt(el.getAttribute("j"));
            this.labelkelurahan = this.serieskelurahan[dataPointIndex];
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            mainAPI.get("datakita-GetKebutuhanFasilitasDetail?random=" + random + "&kecamatan=" + this.labelkecamatan + "&kelurahan=" + this.labelkelurahan).then(
                Response => {
                    this.datadetail = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadetail = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 100) + 1;
                        }
                        //$('#costumModalkelurahan').modal('hide');
                        $('#costumModaldetail').modal('show');
                        this.halamanloading = false;
                    }
                    else {
                        this.halamanloading = false;
                    }

                }
            ).catch(error => {
                console.log(error);
                this.datadetail = [];
                this.jumlahdata = 0;
                this.jumlahhalaman = 0;
                this.mulai = 0;
                this.halaman = 1;
                this.halamanloading = false;
            });
        },
        async dataPointSelectionKecamatan(event) {
            var el = event.target;
            //var seriesIndex = parseInt(el.getAttribute("i"));
            var dataPointIndex = parseInt(el.getAttribute("j"));
            this.labelkecamatan = this.serieskecamatan[dataPointIndex];
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("datakita-GetMKelurahan?random=" + random + "&kecamatan=" + this.labelkecamatan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatemp = [];
                        this.datakelurahan = [];
                        this.datatemp = Response.data.content.data;
                        this.datatemp.forEach((item) => {
                            this.datakelurahan.push({
                                label: item.NAMA,
                                code: this.datatemp.indexOf(item) + 1,
                                tag: item.KODE,
                            });
                        });
                        this.datakelurahan.unshift({
                            label: 'SEMUA DESA',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = '';
                this.datatemp = [];
                this.datakecamatan = [];
                console.log(error);
                this.halamanloading = false;
            });

            this.halamanloading = true;
            var random = Math.random();
            await mainAPI.get("datakita-GetKebutuhanFasilitasKelurahan?random=" + random + "&kecamatan=" + this.labelkecamatan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.serieskelurahan = [];
                        this.serieskelurahan = Response.data.content.label;
                        this.datamasterkelurahan = [];
                        this.datamasterkelurahan = Response.data.content.data;
                        this.chartOptionsTotalInvestasiKelurahan = {
                            xaxis: {
                                categories: Response.data.content.label
                            },
                            yaxis: {
                                tickAmount: 10,
                                labels: {
                                    formatter: function (value) {
                                        return value.toFixed(0).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    }

                                },
                            },
                        };
                        this.seriesTotalInvestasiKelurahan = [
                            {
                                "name": "Jumlah Kebutuhan Fasilitas Jalan (Number of Road Facilities Needs)",
                                "type": "bar",
                                "data": Response.data.content.value
                            },
                        ];

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.chartOptionsTotalInvestasiKelurahan = {
                    xaxis: {
                        categories: []
                    },
                };
                this.chartOptionsTotalInvestasiKelurahan = [];
                console.log(error);
                this.halamanloading = false;
            });
            $('#costumModalkelurahan').modal('show');
        },
        onkelurahanChange(a) {
            try {
                this.selectedkelurahan = this.datakelurahan[a].tag;
                this.pilihdatakelurahan();
            }
            catch (err) {
                return;
            }
        },
        onkecamatanChange(a) {
            try {
                this.selectedkecamatan = this.datakecamatan[a].tag;
                this.pilihdata();
            }
            catch (err) {
                return;
            }
        },
        async pilihdatakelurahan() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("datakita-GetKebutuhanFasilitasKelurahan?random=" + random + "&kecamatan=" + this.labelkecamatan + "&kelurahan=" + this.selectedkelurahan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.serieskelurahan = [];
                        this.serieskelurahan = Response.data.content.label;
                        this.datamasterkelurahan = [];
                        this.datamasterkelurahan = Response.data.content.data;
                        this.chartOptionsTotalInvestasiKelurahan = {
                            xaxis: {
                                categories: Response.data.content.label
                            },
                            yaxis: {
                                tickAmount: 10,
                                labels: {
                                    formatter: function (value) {
                                        return value.toFixed(0).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    }

                                },
                            },
                        };
                        this.seriesTotalInvestasiKelurahan = [
                            {
                                "name": "Jumlah Kebutuhan Fasilitas Jalan (Number of Road Facilities Needs)",
                                "type": "bar",
                                "data": Response.data.content.value
                            },
                        ];

                    }
                    else {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: this.datapesan,
                        });
                        $('.swal2-container').css("z-index", '999999');
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.chartOptionsTotalInvestasiKelurahan = {
                    xaxis: {
                        categories: []
                    },
                };
                this.chartOptionsTotalInvestasiKelurahan = [];
                console.log(error);
                this.halamanloading = false;
            });
        },
        async pilihdata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("datakita-GetKebutuhanFasilitas?random=" + random + "&kodekecamatan=" + this.selectedkecamatan).then(
                Response => {
                    this.datapesan = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.serieskecamatan = [];
                        this.datamaster = [];
                        this.serieskecamatan = Response.data.content.label;
                        this.datamaster = Response.data.content.data;
                        this.chartOptionsTotalInvestasiKecamatan = {
                            xaxis: {
                                categories: Response.data.content.label
                            },
                            yaxis: {
                                tickAmount: 10,
                                labels: {
                                    formatter: function (value) {
                                        return value.toFixed(0).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    }

                                },
                            },
                        };
                        this.seriesTotalInvestasiKecamatan = [
                            {
                                "name": "Jumlah Kebutuhan Fasilitas Jalan (Number of Road Facilities Needs)",
                                "type": "bar",
                                "data": Response.data.content.value
                            },
                        ];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.chartOptionsTotalInvestasiKecamatan = {
                    xaxis: {
                        categories: []
                    },
                };
                this.seriesTotalInvestasiKecamatan = [];
                this.datamaster = [];
                console.log(error);
                this.halamanloading = false;
            });
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("datakita-GetKecamatan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatemp = [];
                        this.datakecamatan = [];
                        this.datatemp = Response.data.content.data;
                        this.datatemp.forEach((item) => {
                            this.datakecamatan.push({
                                label: item.NAMA,
                                code: this.datatemp.indexOf(item) + 1,
                                tag: item.KODE,
                            });
                        });
                        this.datakecamatan.unshift({
                            label: 'SEMUA KECAMATAN',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = '';
                this.datatemp = [];
                this.datakecamatan = [];
                console.log(error);
                this.halamanloading = false;
            });

            this.halamanloading = true;
            var random = Math.random();
            await mainAPI.get("datakita-GetKebutuhanFasilitas?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.serieskecamatan = Response.data.content.label;
                        this.datamaster = Response.data.content.data;
                        this.serieskecamatan = [];
                        this.serieskecamatan = Response.data.content.label;
                        this.chartOptionsTotalInvestasiKecamatan = {
                            xaxis: {
                                categories: Response.data.content.label
                            },
                            yaxis: {
                                tickAmount: 10,
                                labels: {
                                    formatter: function (value) {
                                        return value.toFixed(0).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    }

                                },
                            },
                        };
                        this.seriesTotalInvestasiKecamatan = [
                            {
                                "name": "Jumlah Kebutuhan Fasilitas Jalan (Number of Road Facilities Needs)",
                                "type": "bar",
                                "data": Response.data.content.value
                            },
                        ];

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.chartOptionsTotalInvestasiKecamatan = {
                    xaxis: {
                        categories: []
                    },
                };
                this.seriesTotalInvestasiKecamatan = [];
                console.log(error);
                this.halamanloading = false;
            });


        },
    },
    mounted() {
        this.ambildata();
        $("#costumModaldetail").on('hide.bs.modal', function () {
            $('#costumModalkelurahan').modal('hide');
        });
    },
}
</script>

<style></style>